import React from 'react';
import Dropdown from '../drop-down';

const TrackChairDropdown = ({
    trackChairs,
	value,
	onSelect,
}) => {

	trackChairs.sort((a,b) => {
		const aName = a.member.last_name.toUpperCase();
		const bName = b.member.last_name.toUpperCase();

		return aName > bName ? 1 : (aName < bName ? -1 : 0)
	});

	const options = trackChairs.map(tc => ({value: tc.member.id, label: `${tc.member.first_name} ${tc.member.last_name}`}));

	return (
		<Dropdown
			id="track-chair"
			value={value}
			placeholder="Select a Track Chair"
			options={options}
			onChange={ev => onSelect(ev.target.value)}
		/>
	);
};

export default TrackChairDropdown;