/**
 * Copyright 2021 OpenStack Foundation
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/

import {
    getRequest,
    putRequest,
    postRequest,
    deleteRequest,
    createAction,
    stopLoading,
    startLoading,
    authErrorHandler,
    escapeFilterValue,
    getCSV
} from "openstack-uicore-foundation/lib/utils/actions";

import {getAccessTokenSafely} from "../utils/methods";
import {SELECTION_CLOSED} from "./base-actions";
import Swal from "sweetalert2";
import history from "../history";

export const REQUEST_PRESENTATION = 'REQUEST_PRESENTATION';
export const RECEIVE_PRESENTATION = 'RECEIVE_PRESENTATION';
export const REQUEST_PRESENTATIONS = 'REQUEST_PRESENTATIONS';
export const RECEIVE_PRESENTATIONS = 'RECEIVE_PRESENTATIONS';
export const SET_PRESENTATIONS_LOADED = 'SET_PRESENTATIONS_LOADED';
export const SET_PRESENTATION = 'SET_PRESENTATION';
export const PRESENTATION_VIEWED = 'PRESENTATION_VIEWED';
export const MARK_SELECTION = 'MARK_SELECTION';
export const SELECTION_CLEARED = 'SELECTION_CLEARED';
export const SCORE_CLEARED = 'SCORE_CLEARED';
export const COMMENT_ADDED = 'COMMENT_ADDED';
export const CATEGORY_CHANGE_REQUESTED = 'CATEGORY_CHANGE_REQUESTED';
export const FLAG_CHANGED = 'FLAG_CHANGED';
export const LISTS_CREATED = 'LISTS_CREATED';
export const CLEAR_PRESENTATION = 'CLEAR_PRESENTATION';
export const CAST_PRESENTATION_SCORE = 'CAST_PRESENTATION_SCORE';
export const RECEIVE_PRESENTATION_AVG_SCORE = 'RECEIVE_PRESENTATION_AVG_SCORE';
export const DURATION_CHANGED = 'DURATION_CHANGED';

export const getTrackPresentations = (page, trackSlug, status = null, search = '', tag = null, resetList = true) => async (dispatch, getState) => {
    const {baseState} = getState();
    const {summit, selectionPlan, allTracks} = baseState;
    const accessToken = await getAccessTokenSafely();
    const track = allTracks.find(c => c.slug === trackSlug);

    if (!track) return;

    dispatch(startLoading());

    const filters = [
        'status==Received',
        'is_chair_visible==1',
        `track_id==${track.id}`
    ];

    if (status && status !== 'all') {
        if (status === 'moved') {
            filters.push(`viewed_status==${status}`);
        } else if (status.startsWith('action_')) {
            const actionId = status.substr(7);
            filters.push(`actions==type_id==${actionId}&&is_completed==1`);
        } else {
            filters.push(`track_chairs_status==${status}`);
        }
    }

    if (tag) {
        filters.push(`tags==${tag}`);
    }

    if (search) {
        const escapedTerm = escapeFilterValue(search);
        filters.push(`title=@${escapedTerm},speaker=@${escapedTerm}`);
    }

    const params = {
        page: page,
        per_page: 20,
        access_token: accessToken,
        expand: 'creator, type, updated_by',
        'filter[]': filters,
        relations: [
            'speakers',
            'tags'
        ].join(',')
    };

    return getRequest(
        createAction(REQUEST_PRESENTATIONS),
        createAction(RECEIVE_PRESENTATIONS),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations`,
        authErrorHandler,
        {track, status, search, tag, resetList}
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
        return track;
    }).catch(() => {
        dispatch(createAction(SET_PRESENTATIONS_LOADED)({}));
        dispatch(stopLoading());
    });
};

export const pullMorePresentations = () => (dispatch, getState) => {
    const {presentationsState} = getState();
    const {page, track, filter: {status, search, tag}} = presentationsState;

    dispatch(getTrackPresentations(page + 1, track.slug, status, search, tag, false));
}

export const removeCurrentPresentation =() => (dispatch) => Promise.resolve().then(() => {
    dispatch(createAction(CLEAR_PRESENTATION)())
});

export const getPresentation = (id) => async (dispatch, getState) => {
    const {baseState, presentationsState} = getState();
    const {summit, selectionPlan} = baseState;
    const accessToken = await getAccessTokenSafely();
    const {loadingPresentation, presentation} = presentationsState;

    if (loadingPresentation) return Promise.resolve();
    if (presentation && presentation.id === id) return Promise.resolve();

    const params = {
        access_token: accessToken,
        expand: ['creator',
            'category_changes_requests',
            'type',
            'speakers',
            'tags',
            'comments',
            'comments.creator',
            'updated_by',
            'actions',
            'media_uploads',
            'media_uploads.media_upload_type',
            'extra_questions',
            'extra_questions.question',
            'extra_questions.question.values',
            'links',
            'track_chair_scores',
            'track_chair_scores.type'
            ].join(','),
        relations: [
            'media_uploads',
            'speakers',
            'links',
            'comments',
            'track_chair_scores',
            'category_changes_requests',
            'tags',
        ].join(',')
    };
    dispatch(startLoading());

    return getRequest(
        createAction(REQUEST_PRESENTATION),
        createAction(RECEIVE_PRESENTATION),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${id}`,
        authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });

};

export const getPresentationAvgScore = (id) => async (dispatch, getState) => {

    const {baseState} = getState();
    const {summit, selectionPlan} = baseState;
    const accessToken = await getAccessTokenSafely();

    const params = {
        access_token: accessToken,
        expand: 'none',
        relations: 'track_chair_scores',
        fields: 'track_chair_avg_score'
    };
    dispatch(startLoading());

    return getRequest(
        null,
        createAction(RECEIVE_PRESENTATION_AVG_SCORE),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${id}`,
        authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const exportPresentations = () => async (dispatch, getState) => {
    const {baseState, presentationsState} = getState();
    const accessToken = await getAccessTokenSafely();
    const {summit, selectionPlan} = baseState;
    const {track, filter: {status, search, tag}} = presentationsState;

    const filters = [
        'status==Received',
        'is_chair_visible==1',
        `track_id==${track.id}`
    ];

    if (status) {
        if (status === 'moved') {
            filters.push(`viewed_status==${status}`);
        } else {
            filters.push(`track_chairs_status==${status}`);
        }
    }

    if (tag) {
        filters.push(`tags==${tag}`);
    }

    if (search) {
        const escapedTerm = escapeFilterValue(search);
        filters.push(`title=@${escapedTerm},speaker=@${escapedTerm}`);
    }

    const params = {
        access_token: accessToken,
        expand: 'category_changes_requests,type,speakers,tags,comments, comments.creator',
        'filter[]': filters
    };

    const filename = summit.name + '-Presentations.csv';

    dispatch(getCSV(`${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/csv`, params, filename));

};

export const markAsRead = (presentationId) => async (dispatch, getState) => {
    const {baseState, presentationsState} = getState();
    const {summit, selectionPlan} = baseState;
    const {presentation} = presentationsState;
    const accessToken = await getAccessTokenSafely();

    if (presentation.viewed || !selectionPlan || !summit) return;

    const params = {
        access_token: accessToken,
    };

    putRequest(
        createAction(PRESENTATION_VIEWED),
        createAction("DUMMY_ACTION"),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${presentationId}/view`,
        {},
        authErrorHandler,
    )(params)(dispatch, getState);
};

export const markSelection = (id, collection) => async (dispatch, getState) => {
    const {baseState, presentationsState} = getState();
    const {summit, selectionPlan } = baseState;
    const {track} = presentationsState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token: accessToken,
    };

    postRequest(
        null,
        createAction(MARK_SELECTION)({selection: collection}),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${track.id}/selection-lists/individual/presentation-selections/${collection}/presentations/${id}`,
        {},
      presentationErrorHandler
    )(params)(dispatch, getState).then(() => {
        dispatch(stopLoading());
    });
};

export const removeSelection = (id, collection) => async (dispatch, getState) => {
    const {baseState, presentationsState} = getState();
    const {summit, selectionPlan } = baseState;
    const {track} = presentationsState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token: accessToken,
    };

    deleteRequest(
        null,
        createAction(SELECTION_CLEARED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/tracks/${track.id}/selection-lists/individual/presentation-selections/${collection}/presentations/${id}`,
        {},
      presentationErrorHandler
    )(params)(dispatch, getState).then(() => {
        dispatch(stopLoading());
    });
};

export const clearPresentationRating = (id, score) => async (dispatch, getState) => {
    const {baseState} = getState();
    const {summit, selectionPlan } = baseState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token: accessToken,
    };

    deleteRequest(
      null,
      createAction(SCORE_CLEARED)({scoreId: score.id}),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${id}/track-chair-scores/${score.type.id}`,
      {},
      presentationErrorHandler
    )(params)(dispatch, getState).then(() => {
        dispatch(stopLoading());
        dispatch(getPresentationAvgScore(id));
    });
};

export const postComment = (id, comment, is_public) => async (dispatch, getState) => {
    const {baseState} = getState();
    const {summit, selectionPlan} = baseState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token: accessToken,
        expand: 'creator'
    };

    postRequest(
        null,
        createAction(COMMENT_ADDED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${id}/comments`,
        {body: comment, is_public},
        authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const changePresentationCategory = (id, trackId) => async (dispatch, getState) => {
    const {baseState} = getState();
    const {summit, selectionPlan} = baseState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token: accessToken,
    };

    postRequest(
        null,
        createAction(CATEGORY_CHANGE_REQUESTED),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${id}/category-change-requests`,
        {new_category_id: trackId},
        authErrorHandler
    )(params)(dispatch).then((payload) => {
        dispatch(stopLoading());
    });
};

export const changeFlag = (flag) => async (dispatch, getState) => {
    const {baseState} = getState();
    const {summit, selectionPlan} = baseState;
    const accessToken = await getAccessTokenSafely();
    const {type_id, is_completed, presentation_id, label} = flag;

    dispatch(startLoading());

    dispatch(postComment(presentation_id, `Flag "${label}" marked as ${is_completed ? 'complete' : 'incomplete'}`, false));

    const params = {
        access_token: accessToken,
    };

    if (flag.is_completed) {
        putRequest(
            null,
            createAction(FLAG_CHANGED),
            `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${presentation_id}/actions/${type_id}/complete`,
            {},
            authErrorHandler
        )(params)(dispatch).then((res) => {
            dispatch(stopLoading());
        });
    } else {
        deleteRequest(
            null,
            createAction(FLAG_CHANGED),
            `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${presentation_id}/actions/${type_id}/incomplete`,
            {},
            authErrorHandler
        )(params)(dispatch).then(() => {
            dispatch(stopLoading());
        });
    }
};

export const castPresentationScore = (presentationId, scoreTypeId) => async (dispatch, getState) => {
    const {baseState} = getState();
    const {summit, selectionPlan} = baseState;
    const accessToken = await getAccessTokenSafely();

    dispatch(startLoading());

    const params = {
        access_token: accessToken,
        expand:'type',
    };

    return postRequest(
        null,
        createAction(CAST_PRESENTATION_SCORE),
        `${window.API_BASE_URL}/api/v1/summits/${summit.id}/selection-plans/${selectionPlan.id}/presentations/${presentationId}/track-chair-scores/${scoreTypeId}`,
        {},
      presentationErrorHandler
    )(params)(dispatch, getState).then(() => {
        dispatch(stopLoading());
    });
}

export const changeDuration = (presentationId, duration) => async (dispatch, getState) => {
    const {baseState} = getState();
    const {summit} = baseState;
    const accessToken = await getAccessTokenSafely();

    const params = {
        access_token: accessToken,
    };

    dispatch(startLoading());

    putRequest(
      null,
      createAction(DURATION_CHANGED),
      `${window.API_BASE_URL}/api/v1/summits/${summit.id}/events/${presentationId}`,
      {duration: duration * 60},
      authErrorHandler
    )(params)(dispatch).then(() => {
        dispatch(stopLoading());
    });
};

export const presentationErrorHandler = (err, res) => (dispatch, getState) => {
    const code = err.status;
    let msg = '';
    dispatch(stopLoading());

    if (code === 412) {
        // if validation error most likely is presentation has been moved to another track
        const {presentationsState, baseState} = getState();
        const {track} = presentationsState;
        const {summit, selectionPlan} = baseState;

        for (var [key, value] of Object.entries(err.response.body.errors)) {
            if (isNaN(key)) {
                msg += key + ': ';
            }

            msg += value + '<br>';
        }
        Swal.fire("Validation error", msg, "warning").then((result) => {
            if (result.value) {
                dispatch(getTrackPresentations(1, track.slug));
            }
        });
    } else {
        dispatch(authErrorHandler(err, res));
    }
};